import { useMemo } from "react";
import { useLocation } from "react-router";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

export function useIsTradeOrContractPage() {
  const location = useLocation();
  const isTradeOrContractPage = useMemo(
    () =>
      processEnvServer
        ? false
        : !![
            "context=Contract",
            "context=Trade",
            "contract-sales/contract-sign-in.jsp",
            "trade-sales/trade-sign-in.jsp"
          ].find(
            data =>
              location.pathname.includes(data) || location.search.includes(data)
          ),
    [location.pathname, location.search]
  );
  return {
    isTradeOrContractPage
  };
}
