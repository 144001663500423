import {
  useMobileOrientation,
  isMobile,
  isTablet,
  isMobileOnly
} from "react-device-detect";

// Please refer: https://github.com/duskload/react-device-detect/blob/44d68ccd26e4b3b73adce2744f2d745bae2d42f3/docs/selectors.md

export const isMobileOrTablet: boolean = isMobile || isTablet;
export const isMobileDevice: boolean = isMobileOnly;
export const isTabletDevice: boolean = isTablet;

export const useDeviceOrientation = () => {
  const { isLandscape } = useMobileOrientation();

  return {
    isLandscape: isLandscape,
    isPotrait: !isLandscape
  };
};
