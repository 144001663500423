import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { urlHasPrefix } from "@RHCommerceDev/utils/urlHasPrefix";

export const useLocalization = () => {
  const location = useLocation();
  const localization = useMemo(() => {
    const { matches: supportedLanguage } = urlHasPrefix(
      location.pathname.toLowerCase()
    );

    if (supportedLanguage) {
      return supportedLanguage[1];
    }
    return "";
  }, [location.pathname]);

  return localization || "/us/en";
};
