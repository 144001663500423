import { Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import props from "theme/props";

export interface RHHamburgerProps {
  color?: string;
}

const RHHamburger: React.FC<RHHamburgerProps> = ({ color }) => {
  const desktop = useMediaQuery<Theme>(theme => theme.breakpoints.up(660));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={desktop ? "24px" : "16px"}
      height={desktop ? "24px" : "16px"}
      fill="none"
      {...props}
    >
      {desktop ? (
        <g stroke={color} strokeWidth={0.75}>
          <path d="M1 .625h22M1 11.625h22M1 22.625h22" />
        </g>
      ) : (
        <path
          stroke={color}
          strokeWidth={0.5}
          d="M.5.75h15M.5 8.084h15M.5 15.416h15"
        />
      )}
    </svg>
  );
};
export default RHHamburger;
