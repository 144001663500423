import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const ErrorIcon: FC<SvgIconProps> = props => {
  const style = {
    backgroundColor: "transparent",
    fontSize: 24,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 21 24" {...props} style={style}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-585.000000, -271.000000)">
          <g transform="translate(585.500000, 271.000000)">
            <circle
              stroke="#CA6667"
              fill="transparent"
              cx="10"
              cy="10"
              r="9.5"
            ></circle>
            <text
              fontFamily="none"
              fontSize="15"
              fontWeight="normal"
              letterSpacing="0.276923077"
              fill="#CA6667"
            >
              <tspan x="7.535" y="15">
                !
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default ErrorIcon;
