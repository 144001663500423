import { useContext } from "react";

import { ErrorContext } from "@RHCommerceDev/custom-providers/ErrorProvider";

const useErrorDialog = () => {
  let { showError } = useContext(ErrorContext);

  return {
    showError
  };
};

export default useErrorDialog;
