import { useMemo } from "react";
import { AppEnv } from "@RHCommerceDev/utils-app-env";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";

/** Not a hook */
export const useEnv = (): AppEnv => getGlobal("_env");

/** Is a REAL hook */
export const useEnvHook = (deps: unknown[] = []) =>
  useMemo(() => getGlobal("_env"), [...deps]);

export type { AppEnv };
