import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHAccountIcon: FC<SvgIconProps> = props => {
  const style = {
    backgroundColor: "transparent",
    fontSize: 24,
    fill: "transparent",
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 15 16" {...props} style={style}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Menu/Mobile" stroke="currentColor">
          <path
            clipRule="evenodd"
            d="M7.82106 1C9.6227 1 11.0834 2.79633 11.0834 5.01237C11.0834 7.22842 9.6227 9.02492 7.82106 9.02492C6.01942 9.02492 4.55884 7.22842 4.55884 5.01237C4.55884 2.79633 6.01942 1 7.82106 1Z"
            strokeWidth="0.65"
          />
          <path
            d="M14.6424 15.4448C14.6424 15.4308 14.6421 15.417 14.641 15.4029C14.3693 11.8348 11.4194 9.0249 7.82058 9.0249C4.22162 9.0249 1.27183 11.8348 1 15.4029"
            strokeWidth="0.65"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHAccountIcon;
