import { useSetAtom, useAtomValue, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { SELECTED_GALLERY_COOKIE } from "@RHCommerceDev/utils/constants";

export const selectedGalleryAtom = atomWithStorage<Gallery | null>(
  SELECTED_GALLERY_COOKIE,
  null
);

export const useSelectedGalleryAtomValue = () =>
  useAtomValue(selectedGalleryAtom);
export const useSelectedGallerySetAtom = () => useSetAtom(selectedGalleryAtom);
export const useSelectedGalleryAtom = () => useAtom(selectedGalleryAtom);

export default useSelectedGalleryAtom;
