import {
  createStyles,
  makeStyles,
  Theme
} from "@RHCommerceDev/utils/material-ui-core";
import { FONT_BARON_SANS_LIGHT } from "@RHCommerceDev/utils/constants";

const captionFont = {
  fontFamily: FONT_BARON_SANS_LIGHT,
  fontSize: "11px",
  lineHeight: "13px",
  letterSpacing: "0.04em",
  textTransform: "uppercase"
};

export const buttonStyles = (theme: Theme) => ({
  primaryBlackBtn: {
    ...captionFont,
    backgroundColor: "#000000",
    padding: "17px 30px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#404040"
    },
    "&:disabled": {
      backgroundColor: "#DDDDDD",
      color: "#FFFFFF"
    },
    "&:focus, &:focus-visible, &:focus-within": {
      outline: "1px solid #000",
      outlineOffset: "2px"
    }
  },
  primaryWhiteBtn: {
    ...captionFont,
    backgroundColor: "rgba(0,0,0,0.8)",
    padding: "17px 30px",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(256,256,256,0.9)"
    },
    "&:disabled": {
      color: "#C4C4C4"
    },
    "&:focus, &:focus-visible, &:focus-within": {
      outline: "1px solid #000",
      outlineOffset: "2px"
    }
  },
  secondaryBtn: {
    ...captionFont,
    background: "transparent",
    padding: "17px 30px",
    color: "#000000",
    border: "1px solid #666666",
    "&:hover": {
      border: "1px solid #AEAEAE"
    },
    "&:disabled": {
      border: "1px solid #E6E6E6",
      color: "#C4C4C4"
    },
    "&:focus, &:focus-visible, &:focus-within": {
      outline: "1px solid #000",
      outlineOffset: "2px"
    }
  },
  unstyledBtn: {
    background: "transparent",
    border: "none",
    padding: 0,
    "&:hover": {
      background: "transparent",
      border: "none"
    }
  }
});

const generateStyles = ({
  stylesObj,
  keys
}: {
  stylesObj: (theme: Theme) => { [key: string]: any };
  keys: string[];
}) => {
  return makeStyles((theme: Theme) => {
    const obj = stylesObj(theme);
    const styles = keys.reduce((acc, next) => {
      return {
        ...acc,
        [next]: obj[next] || {}
      };
    }, {});
    return createStyles(styles);
  });
};

const useButtonStylesByBrand = ({ keys }: { keys: string[] }) => {
  // will be used for brand specifc buttons
  return generateStyles({ keys, stylesObj: buttonStyles })() as any;
};

export default useButtonStylesByBrand;
