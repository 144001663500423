import { useEffect, useRef } from "react";

export function useEffectOnce(
  callback: () => void | (() => void),
  condition: boolean,
  deps: React.DependencyList = []
) {
  const hasRun = useRef(false);

  useEffect(() => {
    let cleanup: ReturnType<typeof callback>;
    if (condition && !hasRun.current) {
      hasRun.current = true;
      cleanup = callback();
    }

    return () => {
      if (cleanup && typeof cleanup === "function") {
        cleanup();
      }
    };
  }, [condition, ...deps]);
}
