import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export type CheckboxProps = {
  checked?: boolean;
};

export const RHCheckboxIcon: FC<SvgIconProps & CheckboxProps> = props => {
  const style = {
    fontSize: 20,
    ...props.style
  };

  return props.checked ? (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
      style={style}
    >
      <title>checkbox_on</title>
      <path fill="#666" d="M.5.5h19v19H.5z" />
      <path fill="#666" d="M19 1v18H1V1h18m1-1H0v20h20V0z" />
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2px"
        id="path"
        d="M4.7 10.3l3.7 3.6L16 5"
      />
    </SvgIcon>
  ) : (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
      style={style}
    >
      <title>checkbox_off</title>
      <path fill="#bbb" d="M19 1v18H1V1h18m1-1H0v20h20V0z" />
    </SvgIcon>
  );
};

export default RHCheckboxIcon;
