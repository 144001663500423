import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHInfoIconV2: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 12,
    ...props.style
  };

  return (
    <SvgIcon viewBox="0 0 20 20" {...props} style={style}>
      <g
        id="StyleGuide-+-Components"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="STYLES" transform="translate(-2297.000000, -849.000000)">
          <g id="Group" transform="translate(2134.000000, 105.000000)">
            <g id="Grommet/Info" transform="translate(163.000000, 744.000000)">
              <circle
                id="Oval"
                stroke="currentColor"
                strokeWidth="1"
                cx="10"
                cy="10"
                r="9.5"
              ></circle>
              <text id="i" fontSize="15" fontWeight="300" fill="currentColor">
                <tspan x="8.535" y="15">
                  i
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHInfoIconV2;
