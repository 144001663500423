import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import classNames from "classnames";

export type RHRBabyChildIconProps = SvgIconProps & {
  strokeColor?: string;
  fillColor?: string;
};

export const RHRBabyChildIcon: FC<RHRBabyChildIconProps> = ({
  strokeColor,
  fillColor,
  ...props
}) => {
  return (
    <>
      <SvgIcon
        viewBox="0 0 70 50"
        {...props}
        className={classNames("text-black", props.className)}
      >
        <path
          d="M10.0997 20.751V1.50238H20.8134C27.8009 1.50238 32.3112 4.80532 32.3112 11.2446C32.2557 17.7325 27.7454 20.751 20.8134 20.751H10.0997ZM59.7963 0.954208V20.751H38.4938V0.954208H36.9256V39.6318C35.517 39.5555 33.7128 38.473 30.4446 31.6035L29.3968 29.4593C27.1417 24.727 24.4494 22.2082 20.6538 21.3269H20.8203C28.9597 21.3269 33.8586 17.795 33.8586 11.2446C33.8586 4.69429 28.9042 0.954208 20.7578 0.954208H8.53839V40.2424H10.1066V21.3616H17.4619C22.5273 21.3616 25.0045 24.1233 27.4192 29.0777L28.4115 31.1663C32.1446 38.8408 35.1006 40.1661 36.9325 40.2424C36.9949 40.2424 37.0505 40.2424 37.106 40.2424H38.5007V21.3616H59.8032V40.2424H61.3714V0.954208H59.8032H59.7963Z"
          fill={fillColor}
        />
        <path
          d="M8.61469 42.7751H10.9462C12.0564 42.7751 12.8544 43.3511 12.8544 44.3225C12.8544 44.8568 12.4658 45.3634 11.9246 45.6132C12.556 45.856 13.007 46.3973 13.007 47.0634C13.007 48.1042 12.1674 48.7426 10.9739 48.7426H8.62162V42.7751H8.61469ZM11.0294 45.4536C11.6956 45.4536 12.098 44.9193 12.098 44.3225C12.098 43.6564 11.6886 43.1152 10.9253 43.1152H9.34327V45.4536H11.0294ZM10.9392 48.4026C11.7997 48.4026 12.2576 47.8059 12.2576 47.0634C12.2576 46.3834 11.9107 45.8005 11.1474 45.8005H9.34327V48.4026H10.9392Z"
          fill={fillColor}
        />
        <path
          d="M15.8936 42.7751H16.4695L18.7177 48.7426H17.9822L17.2189 46.7789H14.9777L14.2213 48.7426H13.6315L15.8936 42.7751ZM17.1079 46.4181L16.1018 43.816L15.0887 46.4181H17.1079Z"
          fill={fillColor}
        />
        <path
          d="M19.6893 42.7751H22.0208C23.131 42.7751 23.929 43.3511 23.929 44.3225C23.929 44.8568 23.5404 45.3634 22.9992 45.6132C23.6306 45.856 24.0816 46.3973 24.0816 47.0634C24.0816 48.1042 23.242 48.7426 22.0485 48.7426H19.6962V42.7751H19.6893ZM22.104 45.4536C22.7702 45.4536 23.1726 44.9193 23.1726 44.3225C23.1726 43.6564 22.7632 43.1152 22 43.1152H20.4179V45.4536H22.104ZM22.0138 48.4026C22.8743 48.4026 23.3322 47.8059 23.3322 47.0634C23.3322 46.3834 22.9853 45.8005 22.222 45.8005H20.4179V48.4026H22.0138Z"
          fill={fillColor}
        />
        <path
          d="M26.6837 46.2099L24.6784 42.7751H25.4694L27.1417 45.7034L28.8209 42.7751H29.4385L27.4331 46.2099V48.7426H26.6837V46.2099Z"
          fill={fillColor}
        />
        <path
          d="M35.0589 48.1527C34.5524 48.6385 34.0944 48.8189 33.3867 48.8189C32.3805 48.8189 31.4299 48.2429 31.4299 47.1466C31.4299 46.189 32.0821 45.8143 32.8107 45.391C32.4777 44.9608 32.1793 44.482 32.1793 43.92C32.1793 43.1012 32.8316 42.6779 33.5879 42.6779C34.2957 42.6779 34.9063 43.0943 34.9063 43.8506C34.9063 44.489 34.1708 45.0372 33.685 45.3425L35.17 47.2229C35.51 46.6331 35.5377 46.0086 35.5377 45.3425H36.072C36.072 46.1821 35.9818 46.9315 35.503 47.6462L36.3635 48.7425H35.5239L35.0728 48.1527H35.0589ZM33.0883 45.7172C32.5748 46.0988 32.1793 46.4388 32.1793 47.1327C32.1793 47.8266 32.7205 48.4164 33.4214 48.4164C33.9765 48.4164 34.372 48.1944 34.7259 47.778L33.0883 45.7241V45.7172ZM34.3165 43.8506C34.3165 43.4204 34.0389 43.0526 33.581 43.0526C33.0814 43.0526 32.8732 43.4412 32.8732 43.8922C32.8732 44.3433 33.116 44.7249 33.3867 45.0649C33.8308 44.7665 34.3095 44.4404 34.3095 43.8506H34.3165Z"
          fill={fillColor}
        />
        <path
          d="M43.3441 47.1882C43.0527 48.0348 42.3102 48.8466 41.1584 48.8466C39.3681 48.8466 38.6048 47.0147 38.6048 45.7657C38.6048 44.5167 39.3542 42.6779 41.1584 42.6779C42.2547 42.6779 43.1221 43.3996 43.3441 44.2947L42.8515 44.4682L42.8168 44.3433C42.6572 43.6841 41.9355 43.0596 41.1514 43.0596C39.8122 43.0596 39.3681 44.6833 39.3681 45.7657C39.3681 46.8482 39.8191 48.465 41.1514 48.465C41.998 48.465 42.56 47.8682 42.8029 47.1743L42.8445 47.0494L43.3441 47.1882Z"
          fill={fillColor}
        />
        <path
          d="M44.5376 42.7751H45.287V45.4328H48.2013V42.7751H48.923V48.7426H48.2013V45.8213H45.287V48.7426H44.5376V42.7751Z"
          fill={fillColor}
        />
        <path
          d="M50.5259 42.7751H51.2753V48.7426H50.5259V42.7751Z"
          fill={fillColor}
        />
        <path
          d="M52.8574 42.7751H53.6068V48.4026H56.2783V48.7426H52.8574V42.7751Z"
          fill={fillColor}
        />
        <path
          d="M57.2081 42.7751H58.9289C60.9343 42.7751 61.8363 44.2254 61.8363 45.7658C61.8363 47.3063 60.9135 48.7426 58.9289 48.7426H57.2081V42.7751ZM58.9012 48.4026C60.5179 48.4026 61.0661 47.0912 61.0661 45.7658C61.0661 44.4405 60.5249 43.1221 58.9012 43.1221H57.9367V48.4096H58.9012V48.4026Z"
          fill={fillColor}
        />
      </SvgIcon>
    </>
  );
};

export default RHRBabyChildIcon;
