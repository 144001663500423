// import { getSelectedLocalization } from "utils/getCountryFromUrl";

import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

const useIsHomePage = () => {
  const req = getReqContext();
  const pathname = processEnvServer ? req?.path : window?.location?.pathname;
  // const prefix = getSelectedLocalization() ?? "";
  // return pathname === prefix;
  return /^(\/(gb|us|ca|de|be)\/(en|de|nl|fr))?\/?$/.test(pathname);
};

export default useIsHomePage;
