interface CustomCookieOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict" | undefined;
}

const useCustomSetCookie = (
  name: string,
  value: string,
  customCookieOptions?: CustomCookieOptions
): void => {
  const cookieOptions: CustomCookieOptions = {
    maxAge: customCookieOptions?.maxAge,
    domain: customCookieOptions?.domain || "",
    path: customCookieOptions?.path || "",
    expires: customCookieOptions?.expires
  };

  const cookieString: string =
    `${name}=${value};` +
    (cookieOptions.maxAge ? `max-age=${cookieOptions.maxAge};` : "") +
    (cookieOptions.domain ? `domain=${cookieOptions.domain};` : "") +
    (cookieOptions.path ? `path=${cookieOptions.path};` : "") +
    (cookieOptions.expires ? `expires=${cookieOptions.expires};` : "");

  document.cookie = cookieString;
};

export default useCustomSetCookie;
