import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  MutationUpdaterFunction,
  useMutation
} from "@apollo/client";
import { updateCartMutation } from "@RHCommerceDev/graphql-client/queries/update-cart";
import { useUserSessionAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useCallback } from "react";
import { formatePostalCode } from "@RHCommerceDev/utils/postalcode-validation";

/** There can only be one updateCart */
export function useUpdateCart(
  {
    onCompleted,
    onError,
    callerId
  }: {
    onCompleted?: (data: { updateCart?: CartType }) => Promise<void>;
    onError?: (error: ApolloError) => void;
    callerId: string;
  } = { callerId: "MISSING" }
) {
  const { rhUser: sessionRhUser, currentCartId: sessionCurrentCartId } =
    useUserSessionAtomValue();

  const [callUpdateCart, { loading, error }] = useMutation<
    Mutation,
    MutationUpdateCartArgs
  >(updateCartMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    fetchPolicy: "no-cache",
    onCompleted,
    onError
  });

  const updateCart = useCallback(
    async ({
      postalCode,
      country,
      currentCartId,
      cartId,
      rhUser,
      email,
      shipLabelSidemark,
      giftMessage,
      update
    }: {
      postalCode?: string | null;
      country?: string | null;
      currentCartId?: string | null;
      cartId?: string | null;
      rhUser?: UserType | null;
      email?: string | null;
      shipLabelSidemark?: string;
      giftMessage?: string[];
      update?:
        | MutationUpdaterFunction<
            Mutation,
            MutationUpdateCartArgs,
            DefaultContext,
            ApolloCache<any>
          >
        | undefined;
    }) => {
      if (cartId || currentCartId) {
        return callUpdateCart({
          variables: {
            updateCartInfo: {
              postalCode:
                postalCode && country
                  ? formatePostalCode(postalCode, country)
                  : postalCode,
              country: country,
              shipLabelSidemark,
              giftMessage
            },
            email: email || rhUser?.email || sessionRhUser?.email,
            cartId:
              cartId ||
              currentCartId ||
              sessionCurrentCartId ||
              "MISSING_CART_ID"
          },
          update
        });
      }
    },
    [callUpdateCart, callerId, sessionCurrentCartId, sessionRhUser?.email]
  );

  return {
    updateCart,
    loading,
    error
  };
}
