import App from "app/App.aem.publish";
import { appFactory } from "./common";
declare global {
  interface Window {
    /* use to ttrack some wistia information */
    __mainWistia: any;
  }
}

const { initialize } = appFactory(App);

initialize();
