import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useNewURLStructureParams } from "@RHCommerceDev/hooks/useParams";
import buildPath from "@RHCommerceDev/utils/buildPath";
import yn from "yn";
import useParams from "@RHCommerceDev/hooks/useParams";

const useGetPDPRedirectPath = (data, isSale = false) => {
  const env = useEnv();
  const params = useParams({
    fullSkuId: "",
    sale: "",
    swatch: ""
  });
  const { category } = useNewURLStructureParams();
  const formattedProductName = data?.displayName
    ?.toLowerCase()
    .replace(/\s+/g, "-");
  const isNewURLFeatureEnabled = yn(env.FEATURE_URL_CHANGE);
  const showSale = !(
    Number(data?.saleInfo?.percentSaleSkus) === 0 ||
    Number(data?.saleInfo?.percentSaleSkus) === 100
  );

  let queryParams = "";
  if (showSale && isSale) {
    queryParams = `?sale=${showSale}`;
  }

  if (isNewURLFeatureEnabled && category) {
    return buildPath(`/${category}/pdp/${formattedProductName}${queryParams}`);
  } else {
    return buildPath(`/catalog/product/product.jsp/${data?.id}${queryParams}`);
  }
};

export default useGetPDPRedirectPath;
