import { useEffect } from "react";

export default function useExternalLinks() {
  useEffect(() => {
    let src = "";
    if (origin.includes("rh.com")) {
      src =
        "https://assets.adobedtm.com/05d66ed5262f/393a5866e70d/launch-96e9b17f4962.min.js";
    } else {
      if (origin.includes("stg4") || origin.includes("local")) {
        src =
          "https://assets.adobedtm.com/05d66ed5262f/393a5866e70d/launch-bc58957f192e-development.min.js";
      } else {
        if (origin.includes("stg2") || origin.includes("stg3")) {
          src =
            "https://assets.adobedtm.com/05d66ed5262f/393a5866e70d/launch-e21cd8d51940-staging.min.js";
        }
      }
    }

    if (!src || src === "") return;

    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.async = true;
    // head?.appendChild(script);

    return () => {
      // head?.removeChild(script);
    };
  }, []);
}
