import React, { FC } from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHCartIcon: FC<SvgIconProps> = props => {
  const style = {
    backgroundColor: "transparent",
    fontSize: 24,
    ...props.style
  };

  return (
    <svg viewBox="0 0 21 24" {...props} style={style}>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Menu/Mobile"
          transform="translate(-285.000000, -13.000000)"
          fill="currentColor"
        >
          <path
            d="M305.009948,31.6236268 C305.003017,34.0218016 303.092216,35.9788744 300.751733,35.9859726 L290.248267,35.9859726 C287.906794,35.9788744 285.995993,34.0228156 285.990052,31.6246409 L285.990052,19.6550617 L290.591816,19.6550617 L300.409174,19.6550617 L305.009948,19.6550617 L305.009948,31.6236268 Z M291.581868,18.0265337 C291.581868,15.813926 293.340201,14.0140274 295.501485,14.0140274 C297.660789,14.0140274 299.419122,15.813926 299.419122,18.0265337 L299.419122,18.6410343 L291.581868,18.6410343 L291.581868,18.0265337 Z M300.409174,18.6410343 L300.409174,18.0265337 C300.409174,15.2551969 298.207298,13 295.501485,13 C292.794682,13 290.591816,15.2551969 290.591816,18.0265337 L290.591816,18.6410343 L285,18.6410343 L285,31.6266689 C285.00792,34.5815447 287.361275,36.9908738 290.247277,37 L300.752723,37 C303.637735,36.9918878 305.99109,34.5815447 306,31.6246409 L306,18.6410343 L300.409174,18.6410343 Z"
            id="Fill-1"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default RHCartIcon;
