import { atom, useAtomValue } from "jotai";

// it's not needed since we are going to enable it for all galleries.

// const useFeatureBUFAtom = atom(get =>
//   get(selectedGalleryAtom)?.activePilots?.includes("BUF")
//     ? get(userSessionAtom)?.rhUser?.galleryPilotOptIn
//     : false
// );

const useFeatureBUFAtom = atom(true);

export const useFeatureBUFAtomValue = () => useAtomValue(useFeatureBUFAtom);
