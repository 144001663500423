import usePageLoadAction from "@RHCommerceDev/hooks/usePageLoadAction";

export default (
  dialogAnchorContainerRef: React.RefObject<HTMLElement>,
  deps?: ReadonlyArray<any>
): void => {
  usePageLoadAction(
    () => {
      const anchor = dialogAnchorContainerRef.current!.querySelector(
        "dialog-anchor"
      ) as HTMLElement;

      anchor?.click && anchor!.click();
    },
    {
      matchUrl: ({ pathname, search }) =>
        ["/content/category.jsp"].includes(pathname) &&
        /[?&]context=Trade/.test(search) &&
        /[?&]pfautocta=1/.test(search)
    },
    () => !!dialogAnchorContainerRef.current?.querySelector("dialog-anchor"),
    [dialogAnchorContainerRef.current, ...deps]
  );
};
