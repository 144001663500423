import React from "react";

const RHHeartIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <path
      fill={props.color}
      d="M12 6.563a2.05 2.05 0 0 1-.604 1.46l-3.292 3.291a.147.147 0 0 1-.207 0L4.604 8.022A2.05 2.05 0 0 1 4 6.563c0-.551.215-1.07.604-1.459a2.05 2.05 0 0 1 1.46-.604c.55 0 1.069.215 1.458.604L8 5.58l.476-.476A2.05 2.05 0 0 1 9.934 4.5c.551 0 1.07.215 1.459.604.39.39.604.908.604 1.46H12Z"
    />
  </svg>
);

export default RHHeartIcon;
