import { atom, useAtomValue, useSetAtom } from "jotai";
import { selectAtom } from "jotai/utils";
import { userSessionAtom } from "./useUserSessionAtom";
import { useQuery } from "@apollo/client";
import { getMembershipInfoForSession } from "graphql-client/queries/get-membership-info-for-session";
import { useCountry } from "hooks/useCountry";
import { useUserSessionAtomValue } from "./useUserSessionAtom";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

const membershipInfoAtomValue = selectAtom(
  userSessionAtom,
  userSession => userSession?.membershipInfo ?? {}
);

const membershipInfoAtom = atom<MembershipInfoType>({
  currencyCode: "USD",
  userHasActiveMembership: false,
  userHasCancelledMembership: false,
  userHasExpiredMembership: false,
  membershipId: "",
  membershipSkuIsOnCart: false
});

export const useMembershipInfoAtom = () => {
  const env = useEnv();
  const { loading: loadingSession } = useUserSessionAtomValue();
  const memberShipInfo = useAtomValue(membershipInfoAtom);
  const setMemberShipInfo = useSetAtom(membershipInfoAtom);
  const country = useCountry();
  useQuery(getMembershipInfoForSession, {
    variables: {
      country
    },
    onCompleted: data => {
      setMemberShipInfo(data?.getMembershipInfoForSession);
    },
    skip: yn(env.FEATURE_PERFORMANCE_SESSION_MEMBERSHIP) ? loadingSession : true
  });

  return memberShipInfo;
};

/** @deprecated: let's collectively find an alternative to using this atom  */
export const useMembershipInfoAtomValue = () => {
  const env = useEnv();
  const legacyValue = useAtomValue(membershipInfoAtomValue);
  const newValue = useMembershipInfoAtom();
  return yn(env.FEATURE_PERFORMANCE_SESSION_MEMBERSHIP)
    ? newValue
    : legacyValue;
};
