import { useSetAtom, useAtomValue, useAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { SIP_ID } from "@RHCommerceDev/utils/constants";

const storage = createJSONStorage(() => sessionStorage);

export const sipId = atomWithStorage(SIP_ID, null, storage);

sipId.onMount = setAtom => {
  window.addEventListener("beforeunload", () => {
    setAtom(null);
  });
  return () => {
    window.removeEventListener("beforeunload", () => {
      setAtom(null);
    });
  };
};

export const useSipIdValue = () => useAtomValue(sipId);
export const useSetSipId = () => useSetAtom(sipId);
export const useSipId = () => useAtom(sipId);

export default useSipId;
