import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";

export const cookiePermissionBannerAtom = atom("");

export const useCookiePermissionBannerAtomValue = () =>
  useAtomValue(cookiePermissionBannerAtom);
export const useCookiePermissionBannerSetAtom = () =>
  useSetAtom(cookiePermissionBannerAtom);
export const useCookiePermissionBannerAtom = () =>
  useAtom(cookiePermissionBannerAtom);

export default useCookiePermissionBannerAtom;
