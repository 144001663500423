import { useEffect } from "react";
import EventEmitter, {
  COMPONENT_DID_LOAD_EVENT
} from "@RHCommerceDev/utils-event-emitter";

export function useComponentLoadedListener(
  callback: () => void,
  deps?: Array<any>
) {
  useEffect(() => {
    const componentDidLoad = () => {
      requestAnimationFrame(() => {
        callback();
      });
    };

    EventEmitter.subscribe(COMPONENT_DID_LOAD_EVENT, componentDidLoad);

    return () => {
      EventEmitter.remove(COMPONENT_DID_LOAD_EVENT, componentDidLoad);
    };
  }, [callback, ...(deps || [])]);
}
