import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import classNames from "classnames";

export type RHRLogoIconProps = SvgIconProps & {
  strokeColor?: string;
  fillColor?: string;
};

export const RHRLogoIcon: FC<RHRLogoIconProps> = ({
  strokeColor,
  fillColor,
  ...props
}) => {
  return (
    <>
      <SvgIcon
        viewBox="0 0 124.11 106.67"
        {...props}
        className={classNames("fill-current text-black", props.className)}
      >
        <path
          d="m7.62,63.82V21.5h23.56c15.36,0,25.28,7.26,25.28,21.42-.12,14.27-10.04,20.9-25.28,20.9H7.62ZM116.88,20.29v43.53h-46.84V20.29h-3.45v85.04c-3.09-.17-7.06-2.56-14.25-17.65l-2.31-4.72c-4.95-10.41-10.88-15.94-19.23-17.87h.36c17.9,0,28.67-7.77,28.67-22.17s-10.89-22.63-28.79-22.63H4.17v86.38h3.45v-41.52h16.17c11.13,0,16.58,6.07,21.9,16.96l2.18,4.6c8.21,16.87,14.71,19.79,18.74,19.96.13,0,.26,0,.38,0h3.06v-41.52h46.84v41.52h3.45V20.29h-3.45Z"
          fill={fillColor}
          stroke={"none"}
        />
        <path
          d="m115.88,14.03h1v-6.97h6.3v-.46h-6.3V.69h7.22v-.44h-8.23v13.77Zm-13.31-6.88c0-3.06,1.25-6.71,4.89-6.71s4.88,3.64,4.88,6.71-1.27,6.67-4.88,6.67-4.89-3.6-4.89-6.67m4.89,7.13c4.01,0,5.9-3.54,5.9-7.13S111.54,0,107.47,0s-5.93,3.49-5.93,7.15,1.93,7.13,5.93,7.13m-18.5-.71V.69h2.58c3.99,0,5.45,3.08,5.45,6.45s-1.52,6.42-5.45,6.42h-2.58Zm-1,.46h3.66c4.22,0,6.44-3.14,6.44-6.88s-2.18-6.9-6.44-6.9h-3.66v13.77Zm-9.05,0h7.24v-.46h-6.24V.25h-1v13.77Zm-11.67-6.97V.69h4.05c2.04,0,3.76.96,3.76,3.2s-1.68,3.16-3.7,3.16h-4.1Zm-1,6.97h1v-6.53h2.91c3.68,0,3.43,6.53,6.99,6.53v-.48c-1.83,0-2.29-4.89-5.16-6.09,2.16-.13,4.1-1.16,4.1-3.56,0-2.7-2.51-3.64-4.82-3.64h-5.03v13.77Zm-13.31-6.88c0-3.06,1.25-6.71,4.89-6.71s4.88,3.64,4.88,6.71-1.27,6.67-4.88,6.67-4.89-3.6-4.89-6.67m4.89,7.13c4.01,0,5.9-3.54,5.9-7.13S61.9,0,57.84,0s-5.93,3.49-5.93,7.15,1.93,7.13,5.93,7.13m-19.14-.25h.77l3.26-12.12,3.58,12.12h.77L50.97.25h-.67l-3.41,12.16L43.28.25h-.77l-3.24,12.16L35.78.25h-1.04l3.95,13.77Zm-14.88,0h8.34v-.46h-7.34v-6.51h6.36v-.46h-6.36V.69h7.2v-.44h-8.21v13.77Zm-12.72,0h1v-6.97h7.55v6.97h1V.25h-1v6.34h-7.55V.25h-1v13.77Zm-6.91,0h1V.69h4.16v-.44H0v.44h4.18v13.33Z"
          fill={fillColor}
          stroke={"none"}
        />
      </SvgIcon>
    </>
  );
};

export default RHRLogoIcon;
