import { useCallback, useEffect, useState } from "react";
import isEqual from "react-fast-compare";

/** Allows the parent default/initialState to change the state in the next state cycle */
export function useInitialState<S>(
  initialState: S,
  stateChangedCallback?: () => void
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState);
  const [previousInitialState, setPreviousInitialState] =
    useState<S>(initialState);

  const handleStateChange = useCallback(() => {
    if (stateChangedCallback) stateChangedCallback();
    setPreviousInitialState(initialState);
    setState(initialState);
  }, [initialState, stateChangedCallback]);

  useEffect(() => {
    if (typeof initialState === "object") {
      if (
        !isEqual(initialState, state) &&
        !isEqual(initialState, previousInitialState)
      ) {
        handleStateChange();
      }
    } else if (
      initialState !== state &&
      initialState !== previousInitialState
    ) {
      handleStateChange();
    }
  }, [
    handleStateChange,
    initialState,
    previousInitialState,
    state,
    stateChangedCallback
  ]);
  return [state, setState];
}
