import { useMemo } from "react";
import { useLocation } from "react-router";

function checkIsCheckout(pathname: string = ""): boolean {
  return (
    pathname?.includes("/checkout") &&
    !pathname?.includes("/checkout/shopping_cart.jsp")
  );
}

/**
 * ## is checkout | not shopping_cart
 * uses window.location.pathname
 * (for function that are outside of the react-router provider)
 * */
export function useIsCheckoutByWindowLocation() {
  const pathname = useMemo(() => {
    try {
      return window?.location?.pathname;
    } catch (error) {}
  }, []);
  const isCheckout = useMemo(() => checkIsCheckout(pathname), [pathname]);
  return { isCheckout };
}

/**
 * ## is checkout | not shopping_cart
 * depends on react-router
 * */
export function useIsCheckout() {
  const { pathname } = useLocation();
  const isCheckout = useMemo(() => checkIsCheckout(pathname), [pathname]);
  return { isCheckout };
}
