import React, { FC, useEffect } from "react";
import { anime } from "react-anime";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

type SuccessCheckmarkIconProps = {
  animated?: boolean;
} & SvgIconProps;

export const SuccessCheckmarkIcon: FC<SuccessCheckmarkIconProps> = props => {
  useEffect(() => {
    if (props.animated) {
      const checkTimeline = anime.timeline({
        autoplay: true,
        direction: "normal",
        loop: false
      });
      checkTimeline.add({
        targets: ".check",
        strokeDashoffset: {
          value: [anime.setDashoffset, 0],
          duration: 700,
          delay: 200,
          easing: "easeOutQuart"
        }
      });
    }
  }, [props.animated]);

  return (
    <SvgIcon className="checkmark" viewBox="0 0 32 32" {...props}>
      <circle
        className="circle"
        cx="16"
        cy="16"
        r="15"
        stroke="#CCCCCC"
        strokeWidth="1"
        fill="none"
      />
      <path
        className="check"
        d="M9 16l5 5 9-9"
        fill="none"
        stroke="#000000"
        strokeWidth="1"
        strokeLinecap="round"
      ></path>
    </SvgIcon>
  );
};

SuccessCheckmarkIcon.defaultProps = {
  animated: false
};

export default SuccessCheckmarkIcon;
