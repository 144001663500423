import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

export type AuthenticationDialogCurrentScreen =
  | "SignInWithEmailAndPassword"
  | "SignUpWithEmailAndPassword"
  | "ForgotPasswordStart"
  | "ForgotPassword"
  | "UpdatedPassword"
  | "Welcome"
  | "SignInWithaSecureLink"
  | "CheckYourInbox"
  | "MobileAuthentication"
  | "SmsCodeHandler"
  | "PasswordResetEmail";

const searchParams = new URLSearchParams(window.location.search);

let initialValue: AuthenticationDialogCurrentScreen;

switch (searchParams.get("stytch_token_type")) {
  case "reset_password":
    {
      initialValue = "ForgotPassword";
    }
    break;
  default: {
    initialValue = "SignInWithEmailAndPassword";
  }
}

const authenticationDialogCurrentScreen =
  atom<AuthenticationDialogCurrentScreen>(initialValue);

const useSetAuthenticationDialogCurrentScreenAtom = () =>
  useSetAtom(authenticationDialogCurrentScreen);
const useAuthenticationDialogCurrentScreenAtomValue = () =>
  useAtomValue(authenticationDialogCurrentScreen);
const useAuthenticationDialogCurrentScreenAtom = () =>
  useAtom(authenticationDialogCurrentScreen);

export {
  useAuthenticationDialogCurrentScreenAtom,
  useAuthenticationDialogCurrentScreenAtomValue,
  useSetAuthenticationDialogCurrentScreenAtom
};
