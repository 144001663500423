import { useMemo } from "react";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";

export type CountryCode = "US" | "CA" | "GB" | "DE";

/**
 * Use Country
 *
 * Returns country code example: "US", "CA", "GB"
 * Gets it from the locale useLocalization
 */
export function useCountry() {
  const prefix = useLocalization();
  return useMemo<string>(() => {
    try {
      const [, lowerCaseCountry] = prefix.split("/");
      return (lowerCaseCountry || "US").toUpperCase();
    } catch (error) {
      console.error(error);
      return "US";
    }
  }, [prefix]);
}
