import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHRAccountIcon: FC<SvgIconProps> = props => {
  const style = {
    ...props.style,
    cursor: " pointer"
  };

  return (
    <SvgIcon viewBox="0 0 12 14" {...props} style={style}>
      <g stroke="currentColor" strokeWidth="0">
        <path d="M6.06256 6.55187C5.50732 6.55187 4.96455 6.38722 4.50288 6.07874C4.04121 5.77027 3.68138 5.33182 3.4689 4.81884C3.25642 4.30586 3.20082 3.74139 3.30915 3.19682C3.41747 2.65224 3.68484 2.15202 4.07746 1.7594C4.47008 1.36679 4.9703 1.09941 5.51487 0.991089C6.05945 0.882766 6.62391 0.938361 7.13689 1.15084C7.64987 1.36333 8.08832 1.72315 8.3968 2.18482C8.70527 2.64649 8.86992 3.18926 8.86992 3.74451C8.86908 4.48881 8.57303 5.20238 8.04673 5.72868C7.52043 6.25497 6.80686 6.55102 6.06256 6.55187V6.55187ZM6.06256 1.40755C5.60055 1.40755 5.1489 1.54455 4.76475 1.80123C4.3806 2.05792 4.08119 2.42275 3.90438 2.8496C3.72757 3.27645 3.68131 3.74614 3.77145 4.19928C3.86158 4.65242 4.08407 5.06865 4.41076 5.39535C4.73746 5.72204 5.15369 5.94453 5.60683 6.03466C6.05997 6.1248 6.52966 6.07854 6.95651 5.90173C7.38336 5.72492 7.74819 5.42551 8.00488 5.04136C8.26156 4.65721 8.39856 4.20556 8.39856 3.74355C8.39763 3.12429 8.15122 2.53066 7.71334 2.09277C7.27545 1.65489 6.68182 1.40848 6.06256 1.40755V1.40755Z" />
        <path d="M11.6626 13.7461H0.451681V10.3906C0.456161 9.15635 1.57168 8.15059 2.94096 8.14611H9.17264C10.5422 8.14995 11.6587 9.15635 11.6626 10.3893V13.7461ZM0.942561 13.277H11.173V10.3899C11.1701 9.41459 10.2728 8.61843 9.17264 8.61523H2.9416C1.84304 8.61843 0.945441 9.41523 0.941921 10.3909L0.942561 13.277Z" />
      </g>
    </SvgIcon>
  );
};

export default RHRAccountIcon;
