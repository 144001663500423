import AEMWrapper from "aem/ComponentWrapper";
import loadable from "@loadable/component";
import React from "react";

export default AEMWrapper(
  {
    type: "rh/components/content/rhicon",
    config: {
      emptyLabel: "rhicon",
      isEmpty: _ => false
    }
  },
  loadable(() => import(/* webpackChunkName: "component-rh-icon" */ "."), {
    fallback: <></>
  })
);
