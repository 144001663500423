import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

const searchParams = new URLSearchParams(window.location.search);
let initialValue;

switch (searchParams.get("stytch_token_type")) {
  case "reset_password":
    {
      initialValue = true;
    }
    break;
  default: {
    initialValue = false;
  }
}

const isAuthenticationDialogAtom = atom(initialValue);

const useSetIsAuthenticationDialogAtom = () =>
  useSetAtom(isAuthenticationDialogAtom);
const useIsAuthenticationDialogAtomValue = () =>
  useAtomValue(isAuthenticationDialogAtom);
const useIsAuthenticationDialogAtom = () => useAtom(isAuthenticationDialogAtom);

export {
  useSetIsAuthenticationDialogAtom,
  useIsAuthenticationDialogAtomValue,
  useIsAuthenticationDialogAtom
};
