import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { userSessionAtom } from "./useUserSessionAtom";

const rhUserAtom = selectAtom(userSessionAtom, userSession => {
  return {
    ...(userSession?.rhUser ?? {}),
    loading: userSession?.loading || false,
    isMyAccountIconDisabled: userSession?.isMyAccountIconDisabled
  };
});

export const useRhUserAtomValue = () => useAtomValue(rhUserAtom);
