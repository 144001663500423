import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { useCallback, useState } from "react";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

import {
  IS_USER_SAVE_COOKIE_PREFERENCES,
  USER_COOKIE_PREFERENCES,
  COUNTRY_COOKIE,
  MEASUREMENT_COOKIE,
  LANGUAGE_COOKIE,
  USER_ACCEPTED_ALL_COOKIES
} from "utils/constants";

import { useHistory } from "react-router";
import { goToSelectedCountryUrl } from "utils/getCountryFromUrl";
import { memoryStorage } from "utils/analytics/storage";
import { useMutation } from "@apollo/client";
import { updateSessionMutation } from "graphql-client/queries/user";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import yn from "yn";
import { useUserSessionLazyQuery } from "./queries";

import { useUserSessionSetAtom } from "hooks/atoms";
import { useCookies } from "react-cookie";

export const useCookiePermission = ({
  onSaveCallback
}: {
  onSaveCallback?: () => void;
}) => {
  const [getUserSession] = useUserSessionLazyQuery();
  const history = useHistory();
  const [preferencesFunctionalityCookie, setPreferencesFunctionalityCookie] =
    useState(false);
  const [thirdPartyAnalyticsCookie, setThirdPartyAnalyticsCookie] =
    useState(false);
  const [analyticsCookie, setAnalyticsCookie] = useState(false);
  const [cookies, setCookie] = useCookies(["userAcceptedAllCookies"]);
  const [userAcceptedAllCookies, setUserAcceptedAllCookies] = useState(false);

  const { country, measurement, language } = useUserPreferences();
  const setUserSessionAtom = useUserSessionSetAtom();

  const { setCookieWrapper, setStorageValueWrapper } =
    useCookiesWithPermission();

  const [updateSession] = useMutation<Mutation>(updateSessionMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onCompleted: ({ updateSession }) => {
      setUserSessionAtom(prevData => ({ ...prevData, ...updateSession }));
    }
  });

  const handlePost = useCallback(
    async cookieValues => {
      let acceptedCookieValues = {};

      Object.keys(cookieValues).map(cookie => {
        if (cookieValues[cookie] === "true") {
          acceptedCookieValues[cookie] = true;
          setCookieWrapper(COUNTRY_COOKIE, country);

          if (cookie === "preferencesFunctionalityCookie") {
            setCookieWrapper(MEASUREMENT_COOKIE, measurement, {}, "US");
            setCookieWrapper(LANGUAGE_COOKIE, language, {}, "US");
          }

          if (cookie === "userAcceptedAllCookies") {
            setCookieWrapper(USER_ACCEPTED_ALL_COOKIES, true, {}, "US");
          }
        }
      });
      setCookieWrapper(
        USER_COOKIE_PREFERENCES,
        JSON?.stringify(acceptedCookieValues)
      );

      goToSelectedCountryUrl(country, history, language);

      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: IS_USER_SAVE_COOKIE_PREFERENCES,
          value: "true"
        });
      }

      setUserSessionAtom(userSession => ({
        ...userSession,
        cookiePreferences: {
          ...userSession?.cookiePreferences,
          userSavedCookiePreferences: "true"
        }
      }));

      await updateSession({
        variables: {
          sessionInput: Object.entries(cookieValues)?.map(([key, value]) => ({
            key,
            value
          }))
        }
      });
    },
    [
      setCookieWrapper,
      setStorageValueWrapper,
      country,
      history,
      language,
      getUserSession,
      measurement,
      updateSession
    ]
  );

  const handleAcceptAll = useCallback(() => {
    setCookie("userAcceptedAllCookies", true, { path: "/" });

    memoryStorage.setItem("saveCookiePermission", true);
    memoryStorage.setItem("savedCookiePreference", true);
    sessionStorage.setItem("isGTMLoaded", "true");
    const cookieKeys = {
      userSavedCookiePreferences: "true",
      thirdPartyAnalyticsCookie: "true",
      preferencesFunctionalityCookie: "true",
      analyticsCookie: "true",
      strictlyNecessaryCookie: "true",
      userAcceptedAllCookies: "true"
    };
    handlePost(cookieKeys);
    !!onSaveCallback && onSaveCallback();
  }, [handlePost, onSaveCallback]);

  const handleDenyAll = useCallback(() => {
    setCookie("userAcceptedAllCookies", false, { path: "/" });

    memoryStorage.setItem("saveCookiePermission", true);
    memoryStorage.setItem("isGTMLoad", true);
    memoryStorage.setItem("savedCookiePreference", true);
    const cookieKeys = {
      userSavedCookiePreferences: "true",
      thirdPartyAnalyticsCookie: "false",
      preferencesFunctionalityCookie: "false",
      analyticsCookie: "false",
      strictlyNecessaryCookie: "true",
      userAcceptedAllCookies: "false"
    };
    handlePost(cookieKeys);
    !!onSaveCallback && onSaveCallback();
  }, [handlePost, onSaveCallback]);

  const handleSavePreferences = useCallback(() => {
    memoryStorage.setItem("saveCookiePermission", true);
    // TODO: make temp changes for cookie dialog and options, check other deprecated cookie permissions
    memoryStorage.setItem("savedCookiePreference", true);
    if (yn(analyticsCookie) && yn(thirdPartyAnalyticsCookie)) {
      sessionStorage.setItem("isGTMLoaded", "true");
    }
    const cookieKeys = {
      userSavedCookiePreferences: "true",
      strictlyNecessaryCookie: "true",
      thirdPartyAnalyticsCookie: thirdPartyAnalyticsCookie.toString(),
      preferencesFunctionalityCookie: preferencesFunctionalityCookie.toString(),
      analyticsCookie: analyticsCookie.toString(),
      userAcceptedAllCookies: userAcceptedAllCookies?.toString()
    };
    handlePost(cookieKeys);
    !!onSaveCallback && onSaveCallback();
  }, [
    thirdPartyAnalyticsCookie,
    preferencesFunctionalityCookie,
    analyticsCookie,
    userAcceptedAllCookies,
    handlePost,
    onSaveCallback
  ]);

  return {
    handleSavePreferences,
    handleDenyAll,
    handleAcceptAll,
    setThirdPartyAnalyticsCookie,
    setPreferencesFunctionalityCookie,
    setAnalyticsCookie,
    setUserAcceptedAllCookies,
    preferencesFunctionalityCookie,
    thirdPartyAnalyticsCookie,
    analyticsCookie
  };
};
