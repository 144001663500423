import { useEffect, useRef } from "react";

type CB = () => any;

const useDidMountEffect = (func: CB, deps: Array<any>) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) return func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
