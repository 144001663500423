import { useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  QueryHookOptions,
  QueryResult
} from "@apollo/client/react/types/types";
import { OperationVariables } from "@apollo/client/core";

export function useSensitiveQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  return useQuery(query, Object.assign({}, options, { ssr: false }));
}

export default useSensitiveQuery;
