import { useEffect, useState } from "react";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import throttle from "lodash.throttle";

export const useMousePosition = ({ doNotTrack }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (doNotTrack) {
      return;
    }

    const setFromEvent = throttle(
      e => setPosition({ x: e.clientX, y: e.clientY }),
      250,
      { trailing: true }
    );

    !processEnvServer && window.addEventListener("mousemove", setFromEvent);

    return () => {
      !processEnvServer &&
        window.removeEventListener("mousemove", setFromEvent);
    };
  }, [doNotTrack]);

  return position;
};
