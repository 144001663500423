import type { Brand } from "@RHCommerceDev/types";
import { getGlobal } from "@RHCommerceDev/utils-globals/getGlobal";
const coreDomain = getGlobal("_env").ENV_DOMAIN;

export const BRAND_MAP: { [key: string]: Brand } = {
  [coreDomain]: "RH",
  "rhmodern.": "MO",
  "rhbabyandchild.": "BC",
  "rhteen.": "TN",
  "rhskihouse.": "SH",
  "rhbeachhouse.": "BH",
  "rhoutdoor.": "OD",
  "rhinteriors.": "IN",
  "rhcontemporary.": "CN",
  "local.mononprod.": "MO",
  "local.bcnonprod.": "BC",
  "local.tnnonprod.": "TN",
  "local.shnonprod.": "SH",
  "local.bhnonprod.": "BH",
  "local.innonprod.": "IN",
  "local.cnnonprod.": "CN",
  "mononprod.": "MO",
  "bcnonprod.": "BC",
  "tnnonprod.": "TN",
  "shnonprod.": "SH",
  "bhnonprod.": "BH",
  "innonprod.": "IN",
  "cnnonprod.": "CN"
};
export default (): Brand => {
  const { href } = window.location;
  const matches: { [key: string]: boolean } = {};

  Object.keys(BRAND_MAP).forEach(url => {
    if (href?.includes(url)) {
      matches[BRAND_MAP[url]] = true;
    }
  });

  let brand: Brand = "RH";
  if (matches["RH"]) {
    Object.keys(matches).forEach(key => {
      if (key !== "RH" && matches[key]) {
        brand = key as Brand;
      }
    });
  } else {
    Object.keys(matches).forEach(key => {
      if (matches[key]) {
        brand = key as Brand;
      }
    });
  }

  if (href?.includes("catalog=SH")) return BRAND_MAP["rhskihouse."];
  else if (href?.includes("catalog=BH")) return BRAND_MAP["rhbeachhouse."];
  else if (href?.includes("catalog=OD")) return BRAND_MAP["rhoutdoor."];
  return brand;
};
