import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useRef
} from "react";

export interface SetValue<K> {
  (key: K, mapValue?: MapValue): {
    (value: any): void;
  };
}

export interface MapValue {
  (value: any): any;
}

export const useState = <S,>(
  initialState: S | (() => S),
  defaultMapValue: MapValue = val => val
): [S, Dispatch<SetStateAction<S>>, SetValue<keyof S>] => {
  const [state, setState] = React.useState<S>(initialState);
  const currentState = useRef(state);

  useEffect(() => {
    currentState.current = state;
  }, [state, setState]);

  const setValue = useCallback<SetValue<keyof S>>(
    (key, mapValue = defaultMapValue) =>
      value => {
        currentState.current = {
          ...(currentState.current as any),
          [key]: mapValue(value)
        };

        return setState(currentState.current);
      },
    [currentState, defaultMapValue]
  );

  return [state, setState, setValue];
};

export default useState;
