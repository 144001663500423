import { REQUIRED_PERMISSION_COUNTRIES } from "@RHCommerceDev/utils/constants";
import { useEffect, useState } from "react";
import loadGoogleTagManager from "@RHCommerceDev/utils/loadGoogleTagManager";
import yn from "yn";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { useCookies } from "react-cookie";
import { clearLocalStorage } from "@RHCommerceDev/utils/analytics/custom-analytics/storage";
import {
  useRhUserAtomValue,
  useCookiePreferencesAtomValue
} from "@RHCommerceDev/hooks-atoms";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { getGACookieObject } from "@RHCommerceDev/utils/analytics/utils";

export function useGoogleTagManager() {
  const { akamaiCountryCode } = useRhUserAtomValue();
  const cookiePreferences = useCookiePreferencesAtomValue();
  const [, , removeCookie] = useCookies();
  const [triggerEvent, setTriggerEvent] = useState(0);
  const triggerAnalyticsEvent = memoryStorage.getItem("saveCookiePermission");
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const env = useEnv();
  function setConsent(
    consent: string = "default",
    analytics_storage: string = "denied"
  ) {
    const script = document.createElement("script");
    script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('consent', ${JSON.stringify(consent)}, {
          'analytics_storage': ${JSON.stringify(analytics_storage)}
        });
      `;
    document.body.appendChild(script);
  }

  useEffect(() => {
    if (!yn(env.REMOVE_ANALYTICS_LOADER)) {
      loadGoogleTagManager();
    }
  }, [env.REMOVE_ANALYTICS_LOADER]);

  useEffect(() => {
    if (akamaiCountryCode) {
      if (
        (REQUIRED_PERMISSION_COUNTRIES?.includes(akamaiCountryCode)
          ? akamaiCountryCode
          : cookiePreferences?.cookieRules) ||
        ""
      ) {
        if (
          yn(cookiePreferences?.analyticsCookie) &&
          yn(cookiePreferences?.thirdPartyAnalyticsCookie)
        ) {
          setConsent("update", "granted");
        } else {
          memoryStorage.setItem("isCountryChanged", false);
          Object.keys(getGACookieObject()).forEach(key => {
            removeCookie(key, {
              path: "/",
              expires: oneYearAgo,
              maxAge: 0,
              domain: env?.ENV_ID === "PROD" ? ".rh.com" : ".rhnonprod.com"
            });
          });
          clearLocalStorage();
          setConsent();
          memoryStorage.setItem("isGTMLoad", true);
        }
        setTriggerEvent(1);
        if (triggerAnalyticsEvent) {
          setTriggerEvent(2);
        }
      }
      if (
        !cookiePreferences?.cookieRules &&
        !REQUIRED_PERMISSION_COUNTRIES?.includes(akamaiCountryCode)
      ) {
        setTriggerEvent(3);
        sessionStorage.setItem("isGTMLoaded", "true");
      }
    }
  }, [
    akamaiCountryCode,
    cookiePreferences?.analyticsCookie,
    cookiePreferences?.cookieRules
  ]);

  return { triggerEvent };
}

export default useGoogleTagManager;
