import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";

export const cartProjectionAtom = atom<CartProjection>({});

export const useCartProjectionAtomValue = () =>
  useAtomValue(cartProjectionAtom);
export const useCartProjectionSetAtom = () => useSetAtom(cartProjectionAtom);
export const useCartProjectionAtom = () => useAtom(cartProjectionAtom);

export default useCartProjectionAtom;
