import {
  useTheme,
  useMediaQuery,
  Theme
} from "@RHCommerceDev/utils/material-ui-core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { GridSpacing } from "@material-ui/core/Grid";
/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export default () => {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  const currentBreakpoint =
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs";

  const breakpointSpacing: Array<GridSpacing> = [2, 3, 4, 4, 4];
  return breakpointSpacing[theme.breakpoints.keys.indexOf(currentBreakpoint)];
};
