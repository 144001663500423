import React, { FC } from "react";
import { SvgIcon } from "utils/material-ui-core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RHSearchIcon: FC<SvgIconProps> = props => {
  const style = {
    fontSize: 24,
    ...props.style
  };

  return (
    <SvgIcon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      {...props}
      style={style}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Menu/Mobile" transform="translate(-235.000000, -13.000000)">
          <g
            id="Group"
            transform="translate(236.000000, 14.000000)"
            stroke="currentColor"
          >
            <circle
              id="Oval"
              cx="9.0072381"
              cy="9.0072381"
              r="9.0072381"
            ></circle>
            <line
              x1="15.359619"
              y1="15.3924762"
              x2="22.1380476"
              y2="21.9551429"
              id="Path"
            ></line>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default RHSearchIcon;
