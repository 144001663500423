import { useEffect, useState } from "react";

const useScrollYoffset = () => {
  const [scrollYoffset, setScrollYoffset] = useState<number>(0);
  const handleScroll = () => {
    const yOffset = window.pageYOffset || document.documentElement.scrollTop;
    setScrollYoffset(yOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return scrollYoffset;
};
export default useScrollYoffset;
