import {
  useLocation,
  useParams as useReactRouterParams
} from "react-router-dom";
import { useMemo } from "react";
import sanitizeHtml from "sanitize-html";

export const useParams = <S extends { [key: string]: any }>(
  initialParams: S,
  options?: {
    toLowerCase?: boolean;
  }
): S => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  if (options?.toLowerCase) {
    params.forEach((value, key) => {
      params.set(key.toLowerCase(), sanitizeHtml(value));
    });
  }

  const extractedParams = Object.entries(initialParams).reduce(
    (acc, [key, val]) => {
      const value = params.get(key);
      const newValue = value || val; // Use a new variable to store the updated value
      params.set(key, newValue);
      return {
        ...acc,
        [key]: sanitizeHtml(newValue)
      };
    },
    {} as S // Corrected initialization of accumulator
  );

  return {
    ...extractedParams,
    toString: () => {
      return params.toString();
    }
  };
};

export const useParams2 = <S extends { [key: string]: any }>(
  initialParams: S,
  options?: {
    toLowerCase: boolean;
  }
): { params: S } => {
  const location = useLocation();

  const [extractedParams, params] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    if (options?.toLowerCase) {
      params.forEach((value, key) => {
        params.set(key.toLowerCase(), value);
      });
    }
    const extractedParams = Object.entries(initialParams).reduce(
      (acc, [key, val]) => {
        const value = params.get(key);
        val = value || val;
        params.set(key, val);
        return {
          ...acc,
          [key]: key?.toLowerCase() === "n" ? val : sanitizeHtml(val)
        };
      },
      initialParams
    );
    return [extractedParams, params];
  }, [location]);

  return {
    params: extractedParams
  };
};

export default useParams;

export const useGetAllParams = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const queryParams = {};
  query.forEach((value, key) => {
    queryParams[key] = sanitizeHtml(value);
  });

  return queryParams;
};
export const useNewURLStructureParams = <
  T extends { [key: string]: any }
>() => {
  return useReactRouterParams<T>();
};
