import { useEffect } from "react";
import { useLocation } from "react-router";

type Flags = { [key: string]: boolean };

export type PageLoadAction = () => void;

export type LocationCondition = (location: any) => boolean;

export type ActivateCondition = {
  matchUrl: RegExp | LocationCondition;
};

export type CallCondition = () => boolean;

const didAlreadyCall: Flags = {};

export default (
  action: PageLoadAction,
  activateCondition: ActivateCondition,
  callCondition: CallCondition,
  deps?: ReadonlyArray<any>
): void => {
  const location = useLocation();
  const key = activateCondition.matchUrl.toString();
  let isActive = false;

  if (activateCondition.matchUrl instanceof RegExp) {
    isActive = activateCondition.matchUrl.test(location.search);
  }

  if (activateCondition.matchUrl instanceof Function) {
    isActive = activateCondition.matchUrl(location);
  }

  useEffect(() => {
    const shouldCall =
      document.readyState !== "loading" &&
      isActive &&
      !didAlreadyCall[key] &&
      callCondition();

    if (shouldCall) {
      didAlreadyCall[key] = true;

      setTimeout(action, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, callCondition, key, isActive, ...deps]);
};
