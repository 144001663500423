import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";

export default () => {
  const prefix = useLocalization();
  const membership = {
    id: "my-account/membership.jsp",
    targetUrl: `${prefix}/my-account/membership.jsp`,
    displayName: "Membership",
    labelCode: "membership"
  };

  const paymentMethods = {
    id: "my-account/payment-info.jsp",
    targetUrl: `${prefix}/my-account/payment-info.jsp`,
    displayName: "Payment Methods",
    labelCode: "paymentMethods"
  };

  const orderHistory = {
    id: "/my-account/order-history.jsp",
    targetUrl: `${prefix}/my-account/order-history.jsp`,
    displayName: "Order History",
    labelCode: "orderHistory"
  };

  const wishlist = {
    id: "my-account/wish-list.jsp",
    targetUrl: `${prefix}/my-account/wish-list.jsp`,
    displayName: "WishList",
    labelCode: "wishlist"
  };

  const addressBook = {
    id: "/my-account/address-book.jsp",
    targetUrl: `${prefix}/my-account/address-book.jsp`,
    displayName: "Address Book",
    labelCode: "addressBook"
  };

  const profile = {
    id: "/my-account/profile.jsp",
    targetUrl: `${prefix}/my-account/profile.jsp`,
    displayName: "Profile",
    labelCode: "profile"
  };

  const giftRegistry = {
    id: `/gift-registry`,
    targetUrl: `${prefix}/gift-registry`,
    displayName: "Gift Registry",
    labelCode: "giftRegistry"
  };

  const signOut = {
    id: "/signout",
    targetUrl: `/signout`,
    displayName: "Sign Out",
    labelCode: "signOut"
  };

  const drawerAccountNavigation = [
    membership,
    paymentMethods,
    orderHistory,
    wishlist,
    addressBook,
    profile,
    giftRegistry
  ];

  const inlineAccountNavigation = [
    orderHistory,
    wishlist,
    membership,
    giftRegistry,
    profile,
    signOut
  ];
  return {
    drawerAccountNavigation,
    inlineAccountNavigation
  };
};
