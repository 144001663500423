import {
  queryApp,
  queryLoading,
  AppData,
  App
} from "@RHCommerceDev/graphql-client/queries/app";
import { useQuery } from "@apollo/client";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

export const getAppData = async (client: any) => {
  const { data: { app = {} } = {} as any } = await client.query({
    query: queryApp,
    ssrMode: !!processEnvServer
  });
  return {
    app,
    setApp: (newApp: Partial<App>) => {
      if (newApp !== app) {
        client.writeQuery({
          query: queryApp,
          data: {
            app
          } as AppData
        });
      }
    }
  };
};

export const getLoading = async (client: any) => {
  const { data: { app = {} } = {} as any } = await client.query({
    query: queryApp,
    ssrMode: !!processEnvServer
  });
  return {
    app,
    setApp: (newApp: Partial<App>) => {
      if (newApp !== app) {
        client.writeQuery({
          query: queryLoading,
          data: {
            app
          } as AppData
        });
      }
    }
  };
};

export default (): { app: App; setApp: (app: Partial<App>) => void } => {
  const { data: { app = {} as App } = {} as AppData, client } = useQuery(
    queryApp,
    {
      fetchPolicy: "cache-only",
      ssr: !!processEnvServer
    }
  );
  const oldApp = app;
  return {
    app,
    setApp: (app: Partial<App>) => {
      if (client) {
        if (oldApp?.postalCode !== app?.postalCode) {
          const data = (client?.cache as any).data.data.ROOT_QUERY;
          Object.keys(data)
            .filter(key => {
              return key?.indexOf("product") === 0;
            })
            .forEach(fieldName => {
              client?.cache.evict({ id: "ROOT_QUERY", fieldName });
            });
        }
        client.writeQuery({
          query: queryApp,
          data: {
            ...oldApp,
            ...app
          } as AppData
        });
      }
    }
  };
};
