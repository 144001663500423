import { useEffect, useState } from "react";

/**
 * # Use Change Once Hook
 * ### Changes only once
 * Meant for boolean states really...
 * Not sure about object support
 */
export function useChangeOnce<T>(nextState: T): T {
  const [changed, setChanged] = useState<boolean>(false);
  const [state, setState] = useState<T>(nextState);
  useEffect(() => {
    // State is different and has not changed
    if (state !== nextState && !changed) {
      setChanged(true);
      setState(nextState);
    }
  }, [changed, nextState, state]);
  return state as T;
}
