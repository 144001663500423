import { useEnvHook } from "@RHCommerceDev/hooks/useEnv";
import { useMemo } from "react";

export function useAppId() {
  const env = useEnvHook();

  const isConciergeParam = useMemo(
    () => new URLSearchParams(window.location.search).get("isConcierge"),
    []
  );

  const isConcierge = useMemo(
    () => !!isConciergeParam || (env?.APP_ID || "").includes("CONCIERGE"),
    [isConciergeParam, env.APP_ID]
  );

  const isEstoreParam = useMemo(
    () => new URLSearchParams(window.location.search).get("isEstore"),
    []
  );

  const isEstore = useMemo(
    () =>
      (!!isEstoreParam || !isConciergeParam) &&
      (env?.APP_ID || "").includes("ESTORE"),
    [isEstoreParam, isConciergeParam, env.APP_ID]
  );

  return { isConcierge, isEstore };
}
