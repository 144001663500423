import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import getStorageOptions from "@RHCommerceDev/utils/getCookieOptions";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useAppId } from "@RHCommerceDev/hooks/useAppId";
import { useMutation } from "@apollo/client";
import { updateSessionMutation } from "@RHCommerceDev/graphql-client/queries/user";
import useCustomSetCookie from "@RHCommerceDev/hooks/useCustomSetCookie";
import { CookieSetOptions } from "universal-cookie";
import yn from "yn";
import {
  excludedCookies,
  CookiesKeys,
  LocalSessionStorageKeys,
  storagesMap as storagesItemsMap
} from "@RHCommerceDev/utils/cookies-permission";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { REQUIRED_PERMISSION_COUNTRIES } from "@RHCommerceDev/utils/constants";
import {
  useUserSessionAtomValue,
  useUserSessionSetAtom
} from "@RHCommerceDev/hooks/atoms";
import useCookiePermissionBannerAtom from "@RHCommerceDev/hooks-atoms/useCookiePermissionBannerAtom";

type SetStorageValueWrapperProps = {
  country?: string;
  value: any;
  storageKey: LocalSessionStorageKeys;
  freshCookiePreferences?: SessionUserType["cookiePreferences"];
};

export function setStorageValue({
  storageKey,
  value,
  country,
  freshCookiePreferences
}: SetStorageValueWrapperProps) {
  const user = memoryStorage.getItem("analytics-sessionDetails");
  const permissionCountry = REQUIRED_PERMISSION_COUNTRIES.includes(
    user?.rhUser?.akamaiCountryCode
  )
    ? user?.rhUser?.akamaiCountryCode
    : user?.cookiePreferences?.cookieRules || "";
  const { allowSetStorageItem } = getStorageOptions({
    permissionCountry: country || permissionCountry,
    cookiePreferences: freshCookiePreferences || user?.cookiePreferences,
    storageKey,
    storageType: "localSessionStorage"
  });

  if (allowSetStorageItem) {
    const storagesMap = {
      localStorage,
      sessionStorage
    };

    const storageItem = storagesItemsMap.localSessionStorage[storageKey];

    storagesMap[storageItem.type].setItem(storageKey, value);
  }
}

export const useCookiesWithPermission = () => {
  const { ENV_DOMAIN, ENV_ID } = useEnv();
  const { isConcierge } = useAppId();
  const session = useUserSessionAtomValue();
  const setSessionAtom = useUserSessionSetAtom();
  const cookieSet = useIsoCookies();
  const [, , removeCookie] = useCookies();
  const [permissionCountry, setPermissionCountry] =
    useCookiePermissionBannerAtom();

  const domain = ENV_ID === "LOCAL" ? "local.rhnonprod.com" : `${ENV_DOMAIN}`;
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const clearStoragesValues = useCallback(
    (
      country?: string,
      freshCookiePreferences?: SessionUserType["cookiePreferences"]
    ) => {
      const domains = [".rhnonprod.com", `.${domain}`, `${domain}`];
      const targetPermissionCountry = country || permissionCountry;
      const userCookiePreferences =
        freshCookiePreferences || session?.cookiePreferences;
      const targetFullCookiesAcceptance = !!yn(
        userCookiePreferences?.userAcceptedAllCookies
      );

      const cookiesStorageKeys = Object.keys(storagesItemsMap.cookiesStorage);

      if (
        REQUIRED_PERMISSION_COUNTRIES.includes(targetPermissionCountry) &&
        !targetFullCookiesAcceptance
      ) {
        Object.keys(cookieSet).forEach(key => {
          if (cookiesStorageKeys.includes(key)) {
            const cookieItemPreference =
              storagesItemsMap.cookiesStorage[key as CookiesKeys].preference;
            const acceptedByPreference = !!yn(
              userCookiePreferences?.[cookieItemPreference]
            );
            if (
              !excludedCookies.includes(key) &&
              cookieItemPreference !== "strictlyNecessary" &&
              !acceptedByPreference
            ) {
              domains.forEach(domain => {
                removeCookie(key, {
                  path: "/",
                  expires: oneYearAgo,
                  maxAge: 0,
                  domain
                });
              });
            }
          }
        });

        const storageKeys = Object.keys(
          storagesItemsMap.localSessionStorage
        ) as LocalSessionStorageKeys[];
        const storagesMap = {
          localStorage,
          sessionStorage
        };

        storageKeys.forEach(key => {
          const storageItem = storagesItemsMap.localSessionStorage[key];
          const acceptedByPreference = !!yn(
            userCookiePreferences?.[storageItem.preference]
          );
          if (
            storageItem.preference !== "strictlyNecessary" &&
            !acceptedByPreference
          ) {
            storagesMap[storageItem.type].removeItem(key);
          }
        });
      }
    },
    [cookieSet, domain, oneYearAgo, permissionCountry, removeCookie, session]
  );

  const setCookieWrapper = useCallback(
    (
      name: CookiesKeys,
      value: any,
      outerOptions?: CookieSetOptions,
      country?: string,
      isSessionCookie?: boolean
    ) => {
      const { cookieOptions, allowSetStorageItem } = getStorageOptions({
        permissionCountry: country || permissionCountry,
        cookiePreferences: session?.cookiePreferences,
        storageKey: name,
        storageType: "cookiesStorage",
        domain: isConcierge ? domain : `.${domain}`,
        expiredDate: oneYearAgo
      });
      const customCookieOptions = {
        ...outerOptions,
        ...cookieOptions,
        encode: (cookie: string) => cookie?.toString()?.replace("%20", " ")
      };
      if (isSessionCookie) {
        delete customCookieOptions["expires"];
        delete customCookieOptions["maxAge"];
      }
      if (allowSetStorageItem) {
        useCustomSetCookie(name, value, cookieOptions);
      }
    },
    [
      permissionCountry,
      session?.cookiePreferences,
      isConcierge,
      domain,
      oneYearAgo
    ]
  );

  const setStorageValueWrapper = useCallback(
    ({
      freshCookiePreferences,
      country,
      value,
      storageKey
    }: SetStorageValueWrapperProps) => {
      setStorageValue({
        freshCookiePreferences:
          freshCookiePreferences || session?.cookiePreferences,
        country: country || permissionCountry,
        value,
        storageKey
      });
    },
    [permissionCountry, session?.cookiePreferences]
  );

  const [updateSession] = useMutation<Mutation>(updateSessionMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onCompleted: ({ updateSession }) => {
      setSessionAtom(prevData => ({ ...prevData, ...updateSession }));
    },
    onError() {}
  });

  async function setCookiePermissionCountry(country: string) {
    setPermissionCountry(country);
    if (!isConcierge) {
      await updateSession({
        variables: {
          sessionInput: [
            {
              key: "cookieRules",
              value: country
            }
          ]
        }
      });
    }
  }

  return {
    cookiePermissionCountry: permissionCountry,
    setCookiePermissionCountry,
    setStorageValueWrapper,
    setCookieWrapper,
    clearStoragesValues
  };
};
