import { atom, useSetAtom, useAtomValue, useAtom } from "jotai";

export const selectedCustomerAtom = atom<AccountLookupType>({});

export const useSelectedCustomerAtomValue = () =>
  useAtomValue(selectedCustomerAtom);
export const useSelectedCustomerSetAtom = () =>
  useSetAtom(selectedCustomerAtom);
export const useSelectedCustomerAtom = () => useAtom(selectedCustomerAtom);

export default useSelectedCustomerAtom;
