import * as StytchReact from "@stytch/react";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { getGlobal } from "@RHCommerceDev/utils/Globals/getGlobal";
import yn from "yn";

const env = getGlobal("_env");
const FEATURE_STYTCH = yn(env.FEATURE_STYTCH);

const useStytch = () => {
  if (FEATURE_STYTCH && !processEnvServer) {
    return StytchReact.useStytch();
  }

  return null;
};

export default useStytch;
